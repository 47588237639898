<template>
  <page-content title="帖子详情">
    <a-descriptions :column="1" bordered size="small">
      <a-descriptions-item label="标题">{{forumPost.title}}</a-descriptions-item>
      <a-descriptions-item label="内容">{{forumPost.content}}</a-descriptions-item>
      <a-descriptions-item label="图片视频">
        <div style="display: flex">
          <template v-for="res in JSON.parse(forumPost.res)">
            <video-item class="res" :res="res"/>
          </template>
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <common-table
            ref="table"
            path="web/app-forum-reply"
            :query-params="queryParams"
            :columns="columns">

      <template slot="search">
        <a-button ghost type="primary" @click="add">回复</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <!--        <action-edit @click="edit(record)"></action-edit>-->
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <forum-reply-edit ref="editDrawer" @success="getList"></forum-reply-edit>
  </page-content>
</template>
<script>

import ForumReplyEdit from './ForumReplyEdit'

export default {
  components: {
    ForumReplyEdit,
  },
  data () {
    return {
      forumPost:{},
      forumPostId:this.$route.params.forumPostId,
      queryParams:{
        forumPostId:this.$route.params.forumPostId
      }
    }
  },
  created() {
    this.$get('web/app-forum/'+this.forumPostId).then(suc=>{
      this.forumPost=suc.data
    })
  },

  computed: {
    columns () {
      return [
        {
          title: '用户头像',
          dataIndex: 'avatarUrl',
          scopedSlots: {customRender: 'logo'},
        },
        {
          title: '用户',
          dataIndex: 'nickName',
        },
        {
          title: '回复内容',
          dataIndex: 'content',
        },
        {
          title: '时间',
          dataIndex: 'createdAt'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        }
      ]
    }
  },
  methods: {

    add(){
      this.$refs.editDrawer.show({forumPostId:this.forumPostId})
    },
    edit (record) {
      this.$refs.editDrawer.show(record)
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除该回复?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('web/app-forum-reply/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    },

  }
}
</script>
<style lang="scss" scoped>
  .res{
    width: 80px;height: 80px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
  }
</style>
